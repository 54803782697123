<template>
  <div class="container mescroll-touch">
    <div class="top">
      <img class="menuIcon" src="@/assets/images/menu_comm_icon@2x.png" @click="sidebarShow = true" />
      <div class="searchBox" @click="$router.push('/search')">
        <img class="searchIcon" src="@/assets/images/search_comm_icon@2x.png" />
        <span class="searchInput">请输入关键字搜索</span>
        <!-- <input class="searchInput" type="text" placeholder="请输入关键字搜索" disabled /> -->
      </div>
    </div>
    <div class="department">
      <span :class="{'active_department': item.TCode == SubjectTCode}" v-for="(item, index) in DepartmentList" :key="index" @click="changeDepartment(index, item.TCode, item.Name)">{{item.Name}}</span>
    </div>
    <div class="content">
      <div class="department_classify">
        <span :class="{'department_classify_active': item.SonTCode == OperativeCode}" v-for="(item, index) in OperativeList" :key="index" @click="changeOperative(item.SonTCode, item.SonName)">{{item.SonName}}</span>
      </div>
      <div class="banner" v-if="BannerList.length > 0">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#CA001B">
          <van-swipe-item v-for="(item, index) in BannerList" :key="index" @click="imgclick(item.UrlLink)">
            <img class="bannerImg" :src="item.File_Url" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="block" v-for="(item, index) in List" :key="index">
        <div class="title">
          <div class="text">
            <span>{{item.CodeName}}</span>
          </div>
          <div class="more" @click="toList(item.Code, item.TCode, item.CodeName)">
            <span>更多</span>
            <img src="@/assets/images/more_comm_icon@2x.png" />
          </div>
        </div>
        <div class="listWrapper mescroll-touch" v-if="item.ArtList.length > 0">
          <div class="list">
            <div class="item" v-for="(sonItem, sonIndex) in item.ArtList" :key="sonIndex" @click="toContentPage(sonItem.Id, sonItem.Type)">
              <div class="item_img">
                <img class="item_cover" :src="sonItem.Thumbnail" />
                <img class="playIcon" v-if="item.Type == 1 || item.Type == 3" src="@/assets/images/play_comm_icon@2x.png" />
              </div>
              <div class="item_content">
                <span class="item_title">{{sonItem.Title}}</span>
                <div class="item_nums">
                  <div class="nums">
                    <img src="@/assets/images/browse_comm_icon@2x.png" />
                    <span>{{sonItem.WatchCount}}</span>
                  </div>
                  <div class="nums">
                    <img src="@/assets/images/like_comm_icon@2x.png" />
                    <span>{{sonItem.LikeCount}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Sidebar :sidebarShow="sidebarShow" @popupClose="popupClose" />
  </div>
</template>

<script>
import mixin from '../../common/mixin/mixins'
import Sidebar from '../../components/sidebar.vue'
export default {
  name: 'DigitalSummit',
  data() {
    return {
      sidebarShow: false,
      DepartmentList: [],
      SubjectTCode: '',
      OperativeList: [],
      OperativeCode: '',
      BannerList: [],
      List: []
    }
  },
  mixins: [mixin],
  components: {
    Sidebar
  },
  created() {
    this.getDepartment();
    this.getBanner();
  },
  watch: {
    OperativeList() {
      if(this.OperativeList && this.OperativeList.length > 0) {
        this.OperativeCode = this.OperativeList[0].SonTCode;
      } else {
        this.OperativeCode = '';
      }
    },
    SubjectTCode() {
      this.getList();
    },
    OperativeCode() {
      this.getList();
    }
  },
  methods: {
    getDepartment() {
      this.$axios.get('/Api/api/Web/Article/GetSZHFHCode').then(res => {
        this.DepartmentList = res.Data;
        this.SubjectTCode = res.Data[0].TCode;
        this.OperativeList = res.Data[0].sonList;
      })
    },
    getBanner() {
      this.$axios.get('/Api/api/Web/Article/GetSZHFHImg').then(res => {
        this.BannerList = res.Data;
      })
    },
    getList() {
      let TCode = '';
      if(this.OperativeCode) {
        TCode = this.OperativeCode;
      } else {
        TCode = this.SubjectTCode;
      }
      this.$axios.get('/Api/api/Web/Article/GetSZHFHArtList?TCode=' + TCode).then(res => {
        this.List = res.Data;
      })
    },
    changeDepartment(index, TCode, Name) {
      this.SubjectTCode = TCode;
      this.OperativeList = this.DepartmentList[index].sonList;
      this.BuriedPoint(2, Name, TCode, '/digitalSummit');
    },
    changeOperative(SonTCode, SonName) {
      this.OperativeCode = SonTCode;
      this.BuriedPoint(2, SonName, SonTCode, '/digitalSummit');
    },
    imgclick(UrlLink) {
			let objExp=new RegExp(/^(f|ht)tps?:\/\//i);
      if(UrlLink) {
				if(objExp.test(UrlLink)) {
				  location.href = UrlLink;
				}else{
					this.$router.push(UrlLink)
				}
      }
    },
    toList(Code, TCode, name) {
      this.BuriedPoint(1, name, TCode, '/digitalSummitList');
      this.$router.push({
        path: 'digitalSummitList',
        query: {
          code: Code,
          tcode: TCode,
          title: name
        }
      });
    },
    toContentPage(Id, Type) {
      this.$router.push({
        path: 'contentPage',
        query: {
          id: Id,
          type: Type
        }
      });
    },
    popupClose(val) {
      this.sidebarShow = val;
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100%;
  padding: 16px 0;
  box-sizing: border-box;
  background-image: url('../../assets/images/bg_comm_png@2x.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #F9F9F9;
  .top {
    margin-bottom: 6px;
    display: flex;
    padding: 0 12px;
    .menuIcon {
      width: 30px;
      height: 30px;
    }
    .searchBox {
      display: flex;
      align-items: center;
      flex: 1;
      height: 30px;
      line-height: 30px;
      border-radius: 30px;
      background-color: rgba(255, 255, 255, 0.9);
      padding: 0 15px;
      margin-left: 10px;
      .searchIcon {
        width: 14px;
        height: 14px;
      }
      .searchInput {
        flex: 1;
        margin-left: 4px;
        border: none;
        background-color: transparent;
        color: #999999;
      }
    }
  }
  .department {
    height: 32px;
    line-height: 30px;
    margin: 0 12px;
    box-sizing: border-box;
    font-size: 14px;
    color: #FFFFFF;
    white-space:nowrap;
    overflow-x: scroll;
    span {
      position: relative;
    }
    span:not(:last-child) {
      margin-right: 20px;
    }
    .active_department {
      font-size: 18px;
      font-weight: bold;
    }
    .active_department::after {
      content: "";
      width: 22px;
      height: 3px;
      border-radius: 3px;
      background-color: #FFFFFF;
      position: absolute;
      top: 22px;
      left: 50%;
      transform: translateX(-11px);
    }
  }
  .department::-webkit-scrollbar { display: none; }
  .content {
    padding: 16px 12px 16px;
    background-color: #F9F9F9;
    border-radius: 12px 12px 0 0;
    .department_classify {
      display: flex;
      flex-wrap: wrap;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 23%;
        height: 26px;
        border-radius: 26px;
        border: 1px solid #CCCCCC;
        font-size: 11px;
        color: #666666;
        box-sizing: border-box;
        margin-bottom: 15px;
      }
      span:not(:nth-child(4n)) {
        margin-right: calc(8% / 3);
      }
      .department_classify_active {
        color: #CA001B;
        border: 1px solid #CA001B;
      }
    }
    .banner {
      margin: 0px 0 15px 0;
      background-color: #FFFFFF;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px 0px;
      overflow: hidden;
      .bannerImg {
        width: 100%;
        display: block;
      }
    }
    .block {
      background-color: #FFFFFF;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 10px;
      .title {
        display: flex;
        justify-content: space-between;
        .text {
          display: flex;
          align-items: center;
          font-size: 15px;
          color: #CA001B;
          font-weight: bold;
          img {
            width: 18px;
            margin-right: 8px;
          }
        }
        .more{
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #CA001B;
          img {
            width: 12px;
            height: 12px;
            margin-left: 4px;
          }
        }
      }
      .listWrapper {
        display: flex;
        // overflow: hidden;
        overflow-x: scroll;
        margin-top: 10px;
        .list {
          display: flex;
          flex-wrap: nowrap;
          .item {
            position: relative;
            width: 124px;
            border-radius: 4px;
            background-color: #F5F5F5;
            padding-bottom: 15px;
            overflow: hidden;
            .item_img {
              position: relative;
              overflow: hidden;
              .item_cover {
                display: block;
                width: 100%;
                height: 100%;
              }
              .live_status {
                position: absolute;
                top: 5px;
                left: 5px;
                display: inline-block;
                height: 15px;
                line-height: 15px;
                border-radius: 15px;
                padding: 0 6px;
                font-size: 9px;
                color: #FFFFFF;
                background-color: #000000;
                opacity: 0.5;
              }
              .playIcon {
                position: absolute;
                right: 12px;
                bottom: 12px;
                width: 30px;
                height: 30px;
              }
            }
            .item_content {
              padding: 5px;
              .item_title {
                font-size: 12px;
                color: #333333;
                overflow: hidden;
                text-overflow:ellipsis;
                display:-webkit-box;
                -webkit-box-orient:vertical;
                -webkit-line-clamp:2;
                word-wrap: break-word;
                margin-bottom: 5px;
              }
              .item_nums {
                position: absolute;
                bottom: 6px;
                right: 8px;
                display: flex;
                justify-content: flex-end;
                .nums {
                  display: flex;
                  align-items: center;
                  img {
                    width: 12px;
                    margin: 0 2px 0 6px;
                  }
                  span {
                    font-size: 11px;
                    color: #666666;
                  }
                }
              }
            }
          }
          .item:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
      .listWrapper::-webkit-scrollbar { display: none; }
    }
  }
}
</style>